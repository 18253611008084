import VuHan from './images/imgs/Vu_Han.png'
import PhongDao from './images/imgs/Phong_Dao.png'
import SamreshKumar from './images/imgs/Samresh_Kumar.png'
import TaizoSon from './images/imgs/Taizo_Son.png'
import AtsushiTaira from './images/imgs/Atsushi_Taira.png'
import TomSweeney from './images/imgs/Tom_Sweeney.png'

export const ADVISORS = [
  {
    image: VuHan,
    name: 'Vu Han',
    position: 'CEO and Board Member of Vietnam International Bank',
    linkIn: 'https://www.linkedin.com/in/vu-han-5aa298b/',
    infos: [
      `CEO and Board Member of Vietnam International Bank (Top 2 financial strength in VN by Moody's)`,
      '30 years in Finance (Credit Lyonnais, Calyon, Citigroup). Invested and mentored 100+ Asian startups',
    ],
  },
  {
    image: PhongDao,
    name: 'Phong Dao',
    position: 'Managing Lawyer of Investpush Legal',
    linkIn: 'https://www.linkedin.com/in/phongdao/',
    infos: [
      `Managing Lawyer of Investpush Legal`,
      '20 years in Business and Investment Law. Advised 20+ global blockchain projects',
    ],
  },
  {
    image: SamreshKumar,
    name: 'Samresh Kumar',
    position:
      'Managing Director of VinaCapital (USD 4B AUM), Principle Investments',
    linkIn: 'https://www.linkedin.com/in/samresh/',
    infos: [
      'Managing Director of VinaCapital (USD 4B AUM), Principle Investments',
      '20 years in Investment and Business Building. Managed 200+ deals, raised and invested USD 2B',
    ],
  },
  {
    image: TaizoSon,
    name: 'Taizo Son',
    position: `Japanese Billionaire (2014 Japan's 50 Richest Net Worth)`,
    linkIn: 'http://linkedin.com/in/taizo-son-b6086182/',
    infos: [
      `Japanese Billionaire (2014 Japan's 50 Richest Net Worth)`,
      'Founder of GungHo Online (Over $1B revenue, awarded #1 Appstore revenue on iOs and Android) and Mistletoe ',
    ],
  },
  {
    image: AtsushiTaira,
    name: 'Atsushi Taira',
    position:
      'Co-Founder of Mistletoe Japan and Managing Director of Mistletoe Singapore',
    linkIn: 'https://www.linkedin.com/in/atsushitaira/',
    infos: [
      'Co-Founder of Mistletoe Japan and Managing Director of Mistletoe Singapore',
      '30 years in Investment (Softbank, Mistletoe) with 100+ investments including 4 unicorns; and Web2 C-level roles (Yahoo JP, MySpace JP, SoftBank Mobile)',
    ],
  },
  {
    image: TomSweeney,
    name: 'Tom Sweeney',
    position: 'Managing Partner, JUN Capital Management',
    linkIn: 'https://vn.linkedin.com/in/tomwsweeney',
    infos: [
      'Managing Partner, JUN Capital Management',
      'Former MENA partner at Iris Capital, which manages over USD 3B AUM',
      '30+ years in investment and business strategy consulting ',
    ],
  },
]
